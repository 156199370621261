import AutService from "/src/services/account/auth.service";

export default {
    state: {
        currentUser: localStorage.getItem('username') != null ? JSON.parse(localStorage.getItem('username')) : null,
        loginError: null,
        processing: false,
        forgotMailSuccess: null,
        resetPasswordSuccess: null,
        token: localStorage.getItem('auth') != null ? JSON.parse(localStorage.getItem('auth')) : null,
    },
    getters: {
        currentUser: state => state.currentUser,
        processing: state => state.processing,
        loginError: state => state.loginError,
        forgotMailSuccess: state => state.forgotMailSuccess,
        resetPasswordSuccess: state => state.resetPasswordSuccess,
        isTokenSoonExpires: (state) => {
            if (state.token !== null) {
                let soonInSeconds = 60 * 60 * 8;

                let tokenExpires = new Date(state.token.gotAt);
                tokenExpires.setSeconds(tokenExpires.getSeconds() + state.token.expires_in - soonInSeconds);

                let now = new Date();

                return tokenExpires <= now;
            }
            return false;
        },
        isAuthorized: (state) => {
            return state.currentUser !== null;
        },
    },
    mutations: {
        setUser(state, payload) {
            state.currentUser = payload.currentUser
            state.processing = false
            state.loginError = null
        },
        setToken(state, payload) {
            state.token = payload.token
            state.processing = false
            state.loginError = null
        },
        setLogout(state) {
            state.currentUser = null
            state.processing = false
            state.loginError = null
        },
        setProcessing(state, payload) {
            state.processing = payload
            state.loginError = null
        },
        setError(state, payload) {
            state.loginError = payload
            state.currentUser = null
            state.processing = false
        },
        setForgotMailSuccess(state) {
            state.loginError = null
            state.currentUser = null
            state.processing = false
            state.forgotMailSuccess = true
        },
        setResetPasswordSuccess(state) {
            state.loginError = null
            state.currentUser = null
            state.processing = false
            state.resetPasswordSuccess = true
        },
        clearError(state) {
            state.loginError = null
        }
    },
    actions: {
        login({commit}, payload) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setProcessing', true)
                AutService.login({
                    username: payload.username,
                    password: payload.password
                }).then(function (response) {
                    const token = {
                        access_token: response.access_token,
                        expires_in: response.expires_in,
                        token_type: response.token_type,
                        gotAt: new Date().toISOString()
                    };
                    commit('setToken', {token: token});
                    commit('setUser', {currentUser: payload.username});
                    resolve(response);
                }, function (error) {
                    localStorage.removeItem('username')
                    commit('setError', error.message)
                    setTimeout(() => {
                        commit('clearError')
                    }, 3000);
                    reject(error);
                });
            });
        },
        signOut({commit}) {
            AutService.logout();
            commit('setLogout')
        }
    }
}