import Vue from 'vue'
import Vuex, { Store } from 'vuex'

import user from './modules/user'
import permission from "./modules/permission";
import werehouse from './modules/warehouse'
import Sale from './modules/Sale'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barRespons: true,
    menuRespons: true,
    orderRespons: true,
    selectedClient: null,
    selectedClientSale: null,
    loading: false,
  },
  getters: {
    getBar(state) {
      return state.barRespons
    },
    getMenu(state) {
      return state.menuRespons
    },
    getOrders(state) {
      return state.orderRespons
    },
    getLoading(state) {
      return state.loading
    }
  },
  mutations: {
    showBar(state) {
      state.barRespons = true
      state.menuRespons = false
      state.orderRespons = false
    },
    showMenu(state) {
      state.menuRespons = true
      state.barRespons = false
      state.orderRespons = false
    },
    showOrders(state) {
      state.orderRespons = true
      state.barRespons = false
      state.menuRespons = false
    },
    selectClient(state, {client}) {
        state.selectedClient = client;
    },
    selectClientSale(state, {sale}) {
        state.selectedClientSale = sale
    },
    resetSelectedClientSale(state) {
        state.selectedClientSale = null
    },
    onLoading(state) {
      state.loading = true
    },
    offLoading(state) {
      state.loading = false
    }
  },

  modules: {
    user,
    permission,
    werehouse,
    Sale
  }
})