import axios from "axios";
import querystring from "querystring";
import $bus from "../../plugins/global.events.bus.js";
import * as authEvents from "../../events/auth";
import {ApiHost} from "../../constants/settings";

class AuthService {
    fetchAuth() {
        if (localStorage.auth && localStorage.username) {
            let token = JSON.parse(localStorage.auth);
            axios.defaults.headers.common["Authorization"] = `Bearer ${
                token.access_token
            }`;
            return true;
        }
        return false;
    }

    login({username, password}) {
        return axios.post(
            `${ApiHost}/token`,
            querystring.stringify({
                grant_type: "password",
                username: username,
                password: password
            }),
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then(response => {
            const token = {
                access_token: response.data.access_token,
                expires_in: response.data.expires_in,
                token_type: response.data.token_type,
                gotAt: new Date().toISOString()
            };

            localStorage.auth = JSON.stringify(token);
            localStorage.username = username;

            axios.defaults.headers.common["Authorization"] = `Bearer ${
                token.access_token
            }`;
            //OrganizationLayoutService.syncFront();
            return response.data;
        }).catch((error) => {
            return Promise.reject(error)
        });
    }

    logout() {
        localStorage.removeItem("username");
        localStorage.removeItem("auth");
        localStorage.removeItem('selectedWarehouse')

        delete axios.defaults.headers.common["Authorization"];

        $bus.$emit(authEvents.LOGOUT);
    }
}

export default new AuthService();
