import {ApiHost} from '../../constants/settings'
import axios from "axios";
import $bus from "../../plugins/global.events.bus.js";
import * as authEvents from '../../events/auth'

class AccountSettingsService {
    reloadPermissions({username}) {
        return axios.get(`${ApiHost}/api/AccountPermission/${username}`)
            .then(response => {
                $bus.$emit(authEvents.PERMISSION_RELOADED);
                return response.data
            }, (error) => {
                return Promise.reject(error)
            });
    }

    // changePassword(newPassword) {
    //     return axios.post(`${ApiHost}/api/ChangePassword`, {
    //         Password: newPassword
    //     }).then((response) => {
    //         $bus.$emit(authEvents.PASSWORD_CHANGED);
    //         return response
    //     }).catch(() => {
    //         return Promise.reject(error)
    //     });
    // }
}

export default new AccountSettingsService()