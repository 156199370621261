import BaseService from "../base/base.service";
import axios from "axios";

class ClientService extends BaseService {
    get modelName() {
        return "Client";
    }

    getAll({skip, limit, query, sort, desc, name, debt}) {
        let params = {
            q: (typeof (query) === "undefined" || query === null) ? "" : query,
            sort: (typeof (sort) === "undefined" || sort === null) ? "" : sort,
            desc: desc,
            skip: skip,
            limit: limit,
            name: (typeof (name) === "undefined" || name === null) ? "" : name,
            debt: (typeof (debt) === "undefined" || debt === null) ? null : !!debt
        };

        return axios.get(this.getAllUrl(), {params: params})
            .then(response => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error);
            })
    }

    get(id) {
        return axios.get(this.getSingleUrl(id))
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error);
            })
    }
}

export default new ClientService()