import AccountSettingsService from "/src/services/account/account.settings.service"

export default {
    state: {
        permissions: [],
        isServiceIncluded: JSON.parse(localStorage.getItem('isServiceIncluded')) || true,
    },
    actions: {
        getPermissions({commit}) {
            return new Promise((resolve, reject) => {
                AccountSettingsService.reloadPermissions({username: localStorage.username}).then(function (response) {
                    commit('setPermissions', response.Permissions)
                    resolve(response)
                }, function (error) {
                    reject(error)
                });
            })
        }
    },
    mutations: {
        setPermissions(state, payload) {
            state.permissions = payload;
        },
        setIsServiceIncluded(state, payload) {
            state.isServiceIncluded = payload
        }
    },

    getters: {
        getIsServiceIncluded: state => state.isServiceIncluded,
        isAllowedViewSales: (state) => {
            return state.permissions && state.permissions.indexOf(100) > -1;
        },
        isAllowedAddSale: (state) => {
            return state.permissions && state.permissions.indexOf(101) > -1;
        },
        isAllowedEditSale: (state) => {
            return state.permissions && state.permissions.indexOf(102) > -1;
        },
        isAllowedDeleteSale: (state) => {
            return state.permissions && state.permissions.indexOf(103) > -1;
        },

        isAllowedViewRefunds: (state) => {
            return state.permissions && state.permissions.indexOf(200) > -1;
        },
        isAllowedAddRefund: (state) => {
            return state.permissions && state.permissions.indexOf(201) > -1;
        },
        isAllowedEditRefund: (state) => {
            return state.permissions && state.permissions.indexOf(202) > -1;
        },
        isAllowedDeleteRefund: (state) => {
            return state.permissions && state.permissions.indexOf(203) > -1;
        },

        isAllowedViewPurchases: (state) => {
            return state.permissions && state.permissions.indexOf(300) > -1;
        },
        isAllowedAddPurchase: (state) => {
            return state.permissions && state.permissions.indexOf(301) > -1;
        },
        isAllowedEditPurchase: (state) => {
            return state.permissions && state.permissions.indexOf(302) > -1;
        },
        isAllowedDeletePurchase: (state) => {
            return state.permissions && state.permissions.indexOf(303) > -1;
        },

        isAllowedViewProducts: (state) => {
            return state.permissions && state.permissions.indexOf(400) > -1;
        },
        isAllowedAddProduct: (state) => {
            return state.permissions && state.permissions.indexOf(401) > -1;
        },
        isAllowedEditProduct: (state) => {
            return state.permissions && state.permissions.indexOf(402) > -1;
        },
        isAllowedDeleteProduct: (state) => {
            return state.permissions && state.permissions.indexOf(403) > -1;
        },

        isAllowedViewProductsCategories: (state) => {
            return state.permissions && state.permissions.indexOf(500) > -1;
        },
        isAllowedAddProductsCategory: (state) => {
            return state.permissions && state.permissions.indexOf(501) > -1;
        },
        isAllowedEditProductsCategory: (state) => {
            return state.permissions && state.permissions.indexOf(502) > -1;
        },
        isAllowedDeleteProductsCategory: (state) => {
            return state.permissions && state.permissions.indexOf(503) > -1;
        },

        isAllowedViewCurrencies: (state) => {
            return state.permissions && state.permissions.indexOf(600) > -1;
        },
        isAllowedAddCurrency: (state) => {
            return state.permissions && state.permissions.indexOf(601) > -1;
        },
        isAllowedEditCurrency: (state) => {
            return state.permissions && state.permissions.indexOf(602) > -1;
        },
        isAllowedDeleteCurrency: (state) => {
            return state.permissions && state.permissions.indexOf(603) > -1;
        },

        isAllowedViewProviders: (state) => {
            return state.permissions && state.permissions.indexOf(700) > -1;
        },
        isAllowedAddProvider: (state) => {
            return state.permissions && state.permissions.indexOf(701) > -1;
        },
        isAllowedEditProvider: (state) => {
            return state.permissions && state.permissions.indexOf(702) > -1;
        },
        isAllowedDeleteProvider: (state) => {
            return state.permissions && state.permissions.indexOf(703) > -1;
        },

        isAllowedViewClients: (state) => {
            return state.permissions && state.permissions.indexOf(800) > -1;
        },
        isAllowedAddClient: (state) => {
            return state.permissions && state.permissions.indexOf(801) > -1;
        },
        isAllowedEditClient: (state) => {
            return state.permissions && state.permissions.indexOf(802) > -1;
        },
        isAllowedDeleteClient: (state) => {
            return state.permissions && state.permissions.indexOf(803) > -1;
        },


        isAllowedViewExpenses: (state) => {
            return state.permissions && state.permissions.indexOf(900) > -1;
        },
        isAllowedAddExpense: (state) => {
            return state.permissions && state.permissions.indexOf(901) > -1;
        },
        isAllowedEditExpense: (state) => {
            return state.permissions && state.permissions.indexOf(902) > -1;
        },
        isAllowedDeleteExpense: (state) => {
            return state.permissions && state.permissions.indexOf(903) > -1;
        },

        isAllowedViewDailyReports: (state) => {
            return state.permissions && state.permissions.indexOf(1000) > -1;
        },

        isAllowedViewAccounts: (state) => {
            return state.permissions && state.permissions.indexOf(1100) > -1;
        },
        isAllowedAddAccount: (state) => {
            return state.permissions && state.permissions.indexOf(1101) > -1;
        },
        isAllowedEditAccount: (state) => {
            return state.permissions && state.permissions.indexOf(1102) > -1;
        },

        isAllowedViewMaterials: (state) => {
            return state.permissions && state.permissions.indexOf(1200) > -1;
        },
        isAllowedAddMaterial: (state) => {
            return state.permissions && state.permissions.indexOf(1201) > -1;
        },
        isAllowedEditMaterial: (state) => {
            return state.permissions && state.permissions.indexOf(1202) > -1;
        },
        isAllowedDeleteMaterial: (state) => {
            return state.permissions && state.permissions.indexOf(1203) > -1;
        },

        isAllowedViewRecipes: (state) => {
            return state.permissions && state.permissions.indexOf(1300) > -1;
        },
        isAllowedAddRecipe: (state) => {
            return state.permissions && state.permissions.indexOf(1301) > -1;
        },
        isAllowedEditRecipe: (state) => {
            return state.permissions && state.permissions.indexOf(1302) > -1;
        },
        isAllowedDeleteRecipe: (state) => {
            return state.permissions && state.permissions.indexOf(1303) > -1;
        },

        isAllowedViewCrafts: (state) => {
            return state.permissions && state.permissions.indexOf(1400) > -1;
        },
        isAllowedAddCraft: (state) => {
            return state.permissions && state.permissions.indexOf(1401) > -1;
        },
        isAllowedEditCraft: (state) => {
            return state.permissions && state.permissions.indexOf(1402) > -1;
        },
        isAllowedDeleteCraft: (state) => {
            return state.permissions && state.permissions.indexOf(1403) > -1;
        },

        isAllowedViewProviderPayments: (state) => {
            return state.permissions && state.permissions.indexOf(1500) > -1;
        },
        isAllowedAddProviderPayment: (state) => {
            return state.permissions && state.permissions.indexOf(1501) > -1;
        },
        isAllowedEditProviderPayment: (state) => {
            return state.permissions && state.permissions.indexOf(1502) > -1;
        },

        isAllowedViewMaterialPurchases: (state) => {
            return state.permissions && state.permissions.indexOf(1600) > -1;
        },
        isAllowedAddMaterialPurchase: (state) => {
            return state.permissions && state.permissions.indexOf(1601) > -1;
        },
        isAllowedEditMaterialPurchase: (state) => {
            return state.permissions && state.permissions.indexOf(1602) > -1;
        },
        isAllowedDeleteMaterialPurchase: (state) => {
            return state.permissions && state.permissions.indexOf(1603) > -1;
        },

        isAllowedViewProductsHistory: (state) => {
            return state.permissions && state.permissions.indexOf(1700) > -1;
        },
        isAllowedViewMaterialsHistory: (state) => {
            return state.permissions && state.permissions.indexOf(1800) > -1;
        },
        isAllowedMergeClients: (state) => {
            return state.permissions && state.permissions.indexOf(1900) > -1;
        },
        isAllowedMergeProviders: (state) => {
            return state.permissions && state.permissions.indexOf(2000) > -1;
        },

        isAllowedViewClientSales: (state) => {
            return state.permissions && state.permissions.indexOf(2100) > -1;
        },

        isAllowedViewClientPayments: (state) => {
            return state.permissions && state.permissions.indexOf(2200) > -1;
        },
        isAllowedAddClientPayments: (state) => {
            return state.permissions && state.permissions.indexOf(2201) > -1;
        },

        isAllowedViewClientRefunds: (state) => {
            return state.permissions && state.permissions.indexOf(2300) > -1;
        },

        isAllowedViewOrders: (state) => {
            return state.permissions && state.permissions.indexOf(2400) > -1;
        },
        isAllowedAddOrder: (state) => {
            return state.permissions && state.permissions.indexOf(2401) > -1;
        },
        isAllowedEditOrder: (state) => {
            return state.permissions && state.permissions.indexOf(2402) > -1;
        },
        isAllowedDeleteOrder: (state) => {
            return state.permissions && state.permissions.indexOf(2403) > -1;
        },

        isAllowedViewWarehouseAccount: (state) => {
            return state.permissions && state.permissions.indexOf(2500) > -1;
        },
        isAllowedAddWarehouseAccount: (state) => {
            return state.permissions && state.permissions.indexOf(2501) > -1;
        },
        isAllowedDeleteWarehouseAccount: (state) => {
            return state.permissions && state.permissions.indexOf(2502) > -1;
        },

        isAllowedDashboardStatistics: (state) => {
            return state.permissions && state.permissions.indexOf(2600) > -1;
        },
    }
}