import BaseService from "../base/base.service";
import axios from "axios";

class WarehouseItemService extends BaseService {
    get modelName() {
        return "WarehouseItem";
    }

    getAll({
               warehouseId,
               type,
               skip,
               limit,
               query,
               sort,
               desc,
               name,
               barcode,
               stateeq,
               statelt,
               stategt,
               statelteq,
               stategteq,
               alert,
               category,
               favourite
           }) {
        let params = {
            warehouseId: warehouseId,  //1
            type: type, //0
            q: (typeof (query) === "undefined" || query === null) ? "" : query,
            sort: (typeof (sort) === "undefined" || sort === null) ? "" : sort,
            desc: !!desc,
            skip: skip, //null
            limit: limit, // null
            name: (typeof (name) === "undefined" || name === null) ? "" : name,
            // barcode: (typeof (barcode) === "undefined" || barcode === null) ? "" : barcode,
            stateeq: (typeof (stateeq) === "undefined" || stateeq === null) ? null : +stateeq,
            statelt: (typeof (statelt) === "undefined" || statelt === null) ? null : +statelt,
            stategt: (typeof (stategt) === "undefined" || stategt === null) ? null : +stategt,
            statelteq: (typeof (statelteq) === "undefined" || statelteq === null) ? null : +statelteq,
            stategteq: (typeof (stategteq) === "undefined" || stategteq === null) ? null : +stategteq,
            alert: (typeof (alert) === "undefined" || alert === null) ? null : !!alert,
            category: category ? category.ItemCategoryId : null,
            favourite: (typeof (favourite) === "undefined" || favourite === null) ? null : favourite,
        };

        return axios.get(this.getAllUrl(), {params: params}).then(response => {
            return response.data;
        }, function (error) {
            return Promise.reject(error);
        })
    }

    get(id) {
        return axios.get(this.getSingleUrl(id)).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }
}

export default new WarehouseItemService()