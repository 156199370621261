import BaseService from "../../base/base.service";
import axios from "axios";
import _ from "lodash"

class SaleRegisterService extends BaseService {
    get modelName() {
        return "RegisterSale"
    }

    get(id) {
        return axios.get(this.getSingleUrl(id)).then((response) => {
            let sale = response.data.Sale;
            let sortedSaleItemsById = _.sortBy(response.data.SaleItems, 'SaleItemId');
            let sortedSaleItemsDesc = sortedSaleItemsById.reverse();
            return {sale: sale, saleItems: sortedSaleItemsDesc};
        }).catch((error) => {
            return Promise.reject(error);
        })
    }

    register({
                 ClientId,
                 CreatedAt,
                 WarehouseId,

                 Items
             }) {
        return axios.post(this.getCreateUrl(), {
            Sale: {
                ClientId: ClientId,
                WarehouseId: WarehouseId,
                CreatedAt: CreatedAt ? this._getDateDDMMYYYFormat(CreatedAt) : null,
            },
            SaleItems: _.map(Items, (i) => {
                return {
                    CurrencyValue: {
                        Value: i.Item.Item.PriceCurrencyValue.Value,
                        Rate: i.Item.Item.PriceCurrencyValue.Rate,
                        CurrencyId: i.Item.Item.PriceCurrencyValue.CurrencyId,
                    },
                    DiscountCurrencyValue: {
                        Value: 0,
                        Rate: null,
                        CurrencyId: null
                    },
                    Quantity: i.Quantity,
                    DiscountPercent: 0,
                    ItemId: i.Item.Item.ItemId
                };
            })
        }).then((response) => {
            let sale = response.data.Sale;
            let saleItems = response.data.SaleItems;
            return {sale, saleItems}
        }).catch((error) => {
            return Promise.reject(error);
        })
    }
}

export default new SaleRegisterService()