<template>
  <b-col v-show="getMenu" xl="8" lg="7" md="6" sm="12" cols="12" class="h-100 bg-light" style="font-size: 90% !important;">
    <b-overlay :show="loadingItems" opacity="0.5" spinner-variant="success">
      <div class="first-block">
        <div class="first-block-header border-bottom">
          <h2><b>Hippo.uz</b></h2>
          <b-input-group class="w-50">
            <b-form-input v-model.trim="search" @input="onSearch" placeholder="Поиск...."></b-form-input>
            <b-button class="d-none d-sm-inline-block bg-light text-dark border" @click="search = ''">
              <font-awesome-icon icon="times"/>
            </b-button>

            <b-dropdown variant="outline-success" class="mx-2">
              <template #button-content>
                <b-icon icon="gear-fill" animation="spin" aria-hidden="true"></b-icon>
              </template>
              <b-dropdown-group header="Выберите склад" class="small" style="width: 200px">
                <b-dropdown-item-button class="mx-3"
                                        v-for="warehouse in warehouses"
                                        @click="selectWarehouse(warehouse)"
                                        :key="warehouse.WarehouseId"
                >
                  {{ warehouse.Name }}
                  <span v-if="warehouse.WarehouseId === selectedWarehouse.WarehouseId">
                  <b-iconstack class="text-success fs-6">
                    <b-icon stacked icon="circle"></b-icon>
                    <b-icon stacked icon="check"></b-icon>
                  </b-iconstack>
                </span>
                </b-dropdown-item-button>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item>
                  <router-link
                      class="text-dark text-decoration-none"
                      to="/app/chef-interface"
                  >
                    Окно повара
                  </router-link>
                </b-dropdown-item>
                <b-dropdown-header>
                  <div class="d-flex align-items-center justify-content-between">
                    <span>Обслуживание</span>
                    <switches v-model="isServiceIncluded" theme="bulma" type-bold="true" color="green"></switches>
                  </div>
                </b-dropdown-header>
              </b-dropdown-group>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item-button variant="danger" @click="signOut">
                <font-awesome-icon icon="sign-out-alt"/>
                Выйти
              </b-dropdown-item-button>
            </b-dropdown>
          </b-input-group>
        </div>
        <div>
          <VueScroll>
            <div class="first-block-footer h-100 p-xl-0 p-lg-2 p-md-2 p-sm-2 p-2">
              <b-card
                  :class="{isActive: activeItem === null}"
                  class="card text-center m-xl-3 m-lg-2 m-md-2 m-sm-2 m-2 border-1 border-success pointer"
                  style="width: 110px; height: 110px;"
                  @click="allProd()"
              >
                <b-card-img
                    class="w-50"
                    src="https://cdn.onlinewebfonts.com/svg/img_318548.png" top>
                </b-card-img>
                <b-card-text class="h-50 w-100 d-flex align-items-center justify-content-center">
                  Все категории
                </b-card-text>
              </b-card>

              <b-card
                  v-for="(category, index) in sortedCategory" :key="category.ItemCategoryId"
                  :class="{isActive: index === activeItem}"
                  class="card text-center m-xl-3 m-lg-2 m-md-2 m-sm-2 m-2 border-1 border-success pointer"
                  style="width: 110px; height: 110px;"
                  @click="setTab(category, index)"
              >
                <b-card-img
                    class="w-50"
                    :src="category.ImagePath ? ApiHost + '/images/' + category.ImagePath : categoryImg" top>
                </b-card-img>
                <b-card-text class="h-50 w-100 d-flex align-items-center justify-content-center">
                  {{ category.Name }}
                </b-card-text>
              </b-card>
            </div>
          </VueScroll>
        </div>
      </div>
      <b-container class="second-block p-xl-3 p-lg-2 p-sm-3 p-3 p-md-2 w-100 d-flex flex-wrap">
        <b-card-group deck class="d-flex flex-wrap" v-for="product in productItems" :key="product.WarehouseItemId">
          <b-card
              tag="article"
              class="product-card m-xl-3 m-lg-3 m-md-2 m-sm-2 m-1 text-center border-1 border-success pointer"
              @click="addItem(product)"
          >
            <b-card-img class="resp-img w-50"
                        :src="ApiHost + '/images/' + product.Item.ImagePath"
                        alt="Image"></b-card-img>
            <b-card-text class="h-50 d-flex align-items-center justify-content-center">
              {{ product.Item.Name }} <br>
              {{ product.Item.PriceCurrencyValue.Value | numeral('0,0') }}
            </b-card-text>
            <template #footer>
              <b-button class="w-100 bg-success border-0" variant="primary">
                Добавить
              </b-button>
            </template>
          </b-card>
        </b-card-group>
        <div v-if="!productItems.length" class="isFound w-100 d-flex justify-content-center">
          <img src="https://blog.makuno.co.ke/img/search_error.png" alt="">
        </div>
      </b-container>
    </b-overlay>
  </b-col>
</template>

<script>
import CategoryService from '/src/services/item/category/item.category.service'
import ProductsItemsService from '/src/services/product/werehouse.item.service'
import SaleRegisterService from '/src/services/item/sale/sale.register.service'
import SaleItemService from '/src/services/item/sale/sale.item.service'
import {ApiHost} from "@/constants/settings";
import {mapGetters} from "vuex";
import switches from "vue-switches";
import _ from "lodash"

export default {
  name: 'Menu',
  components: {
    switches
  },
  data() {
    return {
      ApiHost,
      categories: [],
      productItems: [],
      category: null,
      categoryImg: "https://icon-library.com/images/icon-categories/icon-categories-0.jpg",
      isActive: null,
      search: '',
      activeItem: '',
      windowWidth: window.innerWidth,
      Sale: null,
      NewItem: null,
      Items: [],
      loadingItems: false,
    }
  },
  computed: {
    ...mapGetters({
      selectedWarehouse: "selectedWarehouse",
      warehouses: "getAllWarehouses",
    }),
    isServiceIncluded: {
      get() {
        return this.$store.getters.getIsServiceIncluded
      },
      set(newV) {
        localStorage.setItem('isServiceIncluded', newV);
        this.$store.commit('setIsServiceIncluded', newV);
      }
    },
    getMenu() {
      return this.$store.getters.getMenu
    },
    sortedCategory() {
      let sortedCat = _.sortBy(this.categories, ['ItemCategoryId']);
      return sortedCat
    },
    selectedWarehouse() {
      return this.$store.getters.selectedWarehouse;
    },
  },
  watch: {
    selectedWarehouse() {
      if (localStorage.selectedWarehouse) {
        this.allProd()
      }
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
    this.loadCategories();
  },
  methods: {
    resetSale() {
      this.Sale = {
        Client: null,
        DebtCurrencyValue: {
          Currency: null,
          CurrencyId: null,
          CurrencyValueId: null,
          Rate: null,
          Value: 0
        },
        DiscountCurrencyValue: {
          Currency: null,
          CurrencyId: null,
          CurrencyValueId: null,
          Rate: null,
          Value: 0
        },
        PaymentCashCurrencyValue: {
          Currency: null,
          CurrencyId: null,
          CurrencyValueId: null,
          Rate: null,
          Value: 0
        },
        PaymentCardCurrencyValue: {
          Currency: null,
          CurrencyId: null,
          CurrencyValueId: null,
          Rate: null,
          Value: 0
        },
        PaymentBankTransferCurrencyValue: {
          Currency: null,
          CurrencyId: null,
          CurrencyValueId: null,
          Rate: null,
          Value: 0
        },
        HumoTransferCurrencyValue: {
          Currency: null,
          CurrencyId: null,
          CurrencyValueId: null,
          Rate: null,
          Value: 0
        },
        OtherTransferCurrencyValue: {
          Currency: null,
          CurrencyId: null,
          CurrencyValueId: null,
          Rate: null,
          Value: 0
        },
        ClientSaleBonusUseCurrencyValue: {
          Currency: null,
          CurrencyId: null,
          CurrencyValueId: null,
          Rate: null,
          Value: 0
        },
        ClientSaleBonusCurrencyValue: {
          Currency: null,
          CurrencyId: null,
          CurrencyValueId: null,
          Rate: null,
          Value: 0
        },
        TotalPaidCurrencyValue: {
          Currency: null,
          CurrencyId: null,
          CurrencyValueId: null,
          Rate: null,
          Value: 0
        },
        ReturnCurrencyValue: {
          Currency: null,
          CurrencyId: null,
          CurrencyValueId: null,
          Rate: null,
          Value: 0
        },
        ClientPaymentCurrencyValue: {
          Currency: null,
          CurrencyId: null,
          CurrencyValueId: null,
          Rate: null,
          Value: null
        },
        SaleId: null,
        CreatedAt: new Date(),
        IsApproved: false,
        Username: null,
        Total: 0,
        Description: '',
        SaleNumber: null,
        Warehouse: this.$store.getters.selectedWarehouse
      };
    },
    resetNewItem() {
      this.NewItem = {
        Id: null,
        CreatedAt: new Date(),
        PriceCurrencyValue: {
          Currency: null,
          CurrencyId: null,
          CurrencyValueId: null,
          Rate: null,
          Value: null
        },
        Quantity: null,
        PackQuantity: null,
        Item: null,
        Username: null,
        BeforeState: null,
        Total: null,
      }
    },
    addItem(product) {
      let vm = this;
      this.NewItem = product;
      if (this.$store.state.selectedClient != null) {
        vm.loadingItems = true;
        let newItem =
            {
              Quantity: "1",
              DiscountPercent: 0,
              Item: product,
            };
        let saleID = this.$store.state.selectedClientSale ? this.$store.state.selectedClientSale.SaleId : null;
        let sameItem = this.$store.state.Sale.notApprovedItems.find(e => e.WarehouseItem.Item.ItemId === newItem.Item.Item.ItemId)
        if (saleID) {
          if (sameItem) {
            SaleItemService.edit(sameItem.SaleItemId, {
              sale: this.$store.state.selectedClientSale, saleItem: {
                SaleId: saleID,
                ItemId: sameItem.WarehouseItem.Item.ItemId,
                Quantity: ++sameItem.Quantity,
                CurrencyValue: {
                  Value: sameItem.WarehouseItem.Item.PriceCurrencyValue.Value,
                  Rate: sameItem.WarehouseItem.Item.PriceCurrencyValue.Rate,
                  CurrencyId: sameItem.WarehouseItem.Item.PriceCurrencyValue.CurrencyId
                },
              }
            })
                .then((response) => {
                  vm.loadingItems = false;
                  vm.$store.commit('getNewItems', {newItems: response});
                  this.$toast.open({
                    message: "Добавлено: " + newItem.Item.Item.Name,
                    type: "success",
                    duration: 1000,
                    position: 'top-right',
                    dismissible: true
                  })
                }, function (error) {
                  this.$toast.open({
                    message: "Возникла ошибка",
                    type: "error",
                    duration: 4000,
                    position: 'top-right',
                    dismissible: true
                  })
                });
          } else {
            SaleItemService.create({
              sale: this.$store.state.selectedClientSale,
              saleItem: newItem
            }).then(saleItem => {
              vm.loadingItems = false
              vm.Items.unshift(saleItem);
              vm.$store.commit('getNewItems', {newItems: saleItem});
              vm.resetNewItem();
              this.$toast.open({
                message: "Добавлено: " + newItem.Item.Item.Name,
                type: "success",
                duration: 1000,
                position: 'top-right',
                dismissible: true
              })
            })
          }
        } else {
          if (!vm.$store.state.selectedClientSale) {
            let selectedWarehouse = JSON.parse(localStorage.getItem('selectedWarehouse'));
            SaleRegisterService.register({
              Items: [newItem],
              CreatedAt: new Date(),
              WarehouseId: selectedWarehouse.WarehouseId,
              ClientId: this.$store.state.selectedClient.ClientId,
            }).then(({sale, saleItems}) => {
              vm.Sale = sale;
              vm.loadingItems = false;
              _.each(saleItems, saleItem => {
                vm.$store.commit('getNewItems', {newItems: saleItem});
                vm.Items.unshift(saleItem);
              });
              vm.resetNewItem();
              this.$toast.open({
                message: "Добавлено: " + newItem.Item.Item.Name,
                type: "success",
                duration: 1000,
                position: 'top-right',
                dismissible: true
              })
            });
          } else {
            let isSame = this.Items.find(e => e.WarehouseItem.Item.ItemId === newItem.Item.Item.ItemId)
            if (isSame) {
              SaleItemService.edit(isSame.SaleItemId, {
                sale: this.Sale, saleItem: {
                  SaleId: this.Sale.SaleId,
                  ItemId: isSame.WarehouseItem.Item.ItemId,
                  Quantity: ++isSame.Quantity,
                  CurrencyValue: {
                    Value: isSame.WarehouseItem.Item.PriceCurrencyValue.Value,
                    Rate: isSame.WarehouseItem.Item.PriceCurrencyValue.Rate,
                    CurrencyId: isSame.WarehouseItem.Item.PriceCurrencyValue.CurrencyId
                  },
                }
              })
                  .then((response) => {
                    vm.loadingItems = false;
                    vm.$store.commit('getNewItems', {newItems: response});
                    this.$toast.open({
                      message: "Добавлено: " + newItem.Item.Item.Name,
                      type: "success",
                      duration: 1000,
                      position: 'top-right',
                      dismissible: true
                    })
                  }, function (error) {
                    this.$toast.open({
                      message: "Возникла ошибка",
                      type: "error",
                      duration: 4000,
                      position: 'top-right',
                      dismissible: true
                    })
                  });
            } else {
              SaleItemService.create({
                sale: this.Sale,
                saleItem: newItem
              }).then(saleItem => {
                vm.loadingItems = false;
                vm.Items.unshift(saleItem);
                vm.$store.commit('getNewItems', {newItems: saleItem});
                vm.resetNewItem();
                this.$toast.open({
                  message: "Добавлено: " + newItem.Item.Item.Name,
                  type: "success",
                  duration: 1000,
                  position: 'top-right',
                  dismissible: true
                })
              })
            }
          }
        }
      } else {
        this.$toast.open({
          message: "Выберите стол",
          type: "error",
          duration: 3000,
          position: 'top-right',
          dismissible: true
        })
      }
    },
    setTab(category, index) {
      this.isActive = true
      this.activeItem = index
      this.category = category;
      this.loadProducts()
    },

    allProd() {
      this.activeItem = null;
      this.category = null;
      this.loadProducts()
    },
    selectWarehouse(warehouse) {
      let vm = this;
      vm.$store.commit('selectWarehouse', {warehouse: warehouse});
      localStorage.setItem('selectedWarehouse', JSON.stringify(warehouse));
      location.reload()
    },
    loadProducts() {
      this.loadingItems = true;
      let selectedWarehouse = JSON.parse(localStorage.getItem('selectedWarehouse'));
      ProductsItemsService.getAll({
        warehouseId: selectedWarehouse.WarehouseId,
        type: 1,
        query: this.search,
        desc: null,
        sort: null,
        skip: null,
        limit: null,
        stateeq: null,
        statelt: null,
        stategt: null,
        statelteq: null,
        stategteq: null,
        alert: null,
        category: this.category
      }).then(productItems => {
        this.loadingItems = false,
            this.productItems = productItems;
      });
    },

    loadCategories() {
      CategoryService.getAll({
        query: null,
        desc: true,
        sort: null,
        skip: null,
        limit: null
      }).then(categories => {
        this.categories = categories;
      })
    },

    onSearch: _.debounce(function () {
      let vm = this;
      vm.loadProducts()
    }, 500),

    signOut() {
      let vm = this;
      vm.$store.dispatch('signOut').then(() => {
            vm.$router.push({name: 'login'})
          }
      )
    }
  },
  created() {
    this.resetSale();
    this.resetNewItem();
  },
}
</script>

<style scoped>
.vue-switcher--bold >>> div {
  top: 0;
  width: 40px;
  height: 22px;
}
.vue-switcher >>> div::after {
  width: 17px;
  height: 16px;
  top: 2.9px;
}

.first-block {
  width: 100%;
  height: 230px;
  padding: 0 20px;
  border-bottom: 1px solid #000;
}

.first-block-header {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.first-block-footer {
  width: 100%;
  display: flex;
  align-items: center;
  height: 120px;
}

.pointer {
  cursor: pointer;
}

.isActive {
  background: green;
  color: #fff;
}

.product-card {
  max-width: 10rem;
}


/* ////////////////////////////////////////// */

.second-block {
  min-width: 100%;
  max-height: calc(100vh - 230px);
  overflow-x: auto;
}

.second-block::-webkit-scrollbar {
  background-color: #f8f9fa;
  border-radius: 9px;
  width: 5px;
  height: 8px;
}

.second-block::-webkit-scrollbar-thumb {
  background: rgb(193, 193, 193);
  border-radius: 9px;
  background-clip: content-box;
}

@media only screen and (min-width: 767px) {
  /*.hasHBar  >>> .__panel {*/
  /*  overflow: hidden !important;*/
  /*}*/
  .hasHBar >>> .__panel::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (max-width: 767px) and (min-width: 200px) {
  .second-block {
    max-height: calc(100vh - 280px);
  }

  .first-block {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 500px) {
  .second-block {
    justify-content: space-between !important;
  }
}

@media only screen and (max-width: 372px) {
  .product-card {
    max-width: 100%;
    display: inline !important;
  }
}
</style>