<template>
  <b-col v-show="getOrders" class="border-left border-primary shadow" xl="3" lg="4" md="5" sm="12" style="font-size: 90% !important;">
    <b-overlay :show="loadingOrders" v-if="Items" spinner-variant="success">
      <div class="orders-header w-100 border-bottom">
        <h3 v-if="!this.$store.state.selectedClient">Заказы</h3>
        <h3 v-if="this.$store.state.selectedClient">{{ this.$store.state.selectedClient.Name }}</h3>
      </div>
      <b-container class=" p-3 scrolling">
        <div class="mt-5 text-center" v-if="!Items.length">
          <h3 class="text-center">Закажите что-нибудь</h3>
          <img class="w-75"
               src="https://thumbs.dreamstime.com/b/order-food-online-man-holding-smartphone-vector-illustration-flat-design-concept-shopping-application-orders-97658871.jpg"
               alt="">
        </div>
        <b-card no-body style="min-width: 100%;" class="border-success mt-2" v-for="(item,index) in Items" :key="index">
          <b-row no-gutters>
            <b-col cols="3" class="d-flex justify-content-center align-items-center">
              <b-card-img :src="ApiHost + '/images/' + item.WarehouseItem.Item.ImagePath" alt="Image"
                          class="w-75"></b-card-img>
            </b-col>
            <b-col cols="5" class="p-0">
              <b-card-body>
                <b-card-group columns>
                  <b-card-text>{{ item.WarehouseItem.Item.Name }}</b-card-text>
                  <div class="counter">
                    <button @click="min(item)" class="min">-</button>
                    <div>{{ item.Quantity }}</div>
                    <button @click="plus(item)" class="plus">+</button>
                  </div>
                </b-card-group>
              </b-card-body>
            </b-col>

            <b-col cols="4" class="p-0">
              <b-card-body>
                <b-card-group class=" text-center" columns>
                  <b-card-text>{{ item.Total | decimal }} сум</b-card-text>
                  <div class="w-100 text-center">
                    <font-awesome-icon icon="trash" @click="removeProduct(item)" class="t-icon"/>
                  </div>
                </b-card-group>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </b-container>
      <div v-if="Items.length" class="orders-footer w-100 shadow border-top p-2">
        <div class=" d-flex justify-content-between w-100">
          <p><b>Общий счет: </b></p>
          <p><b>{{ getTotal | decimal }}</b> сум</p>
        </div>
        <div v-if="$store.getters.getIsServiceIncluded" class=" d-flex justify-content-between w-100">
          <p>Обслуживание(20%):</p>
          <p>{{ getService | decimal }} сум</p>
        </div>
        <div class=" d-flex justify-content-between w-100">
          <p>Итого: </p>
          <p>{{ getService + getTotal | decimal }} сум</p>
        </div>
        <div class="text-center w-100">
          <b-button v-b-modal.modal1 @click="openModal" variant="success">Закрыть заказ</b-button>
        </div>
      </div>

      <!-- ///////////////////////MODAL WINDOW//////////////////////////////// -->

      <b-modal id="modal1" ref="my-modal" body-class=" p-0" size="fullscreen" hide-header hide-footer
               title="Extra Large Modal">
        <b-container fluid class="p-0 main h-100 d-flex flex-md-wrap flex-sm-wrap flex-wrap">
          <div class=" col-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
            <b-container fluid class="d-none flex-wrap d-xl-flex d-lg-flex d-md-flex d-sm-none">
              <div class=" w-100 d-flex">
                <b-button @click="addNum(7)" class="numbers bg-light text-dark fs-1 m-2">7</b-button>
                <b-button @click="addNum(8)" class="numbers bg-light text-dark fs-1 m-2">8</b-button>
                <b-button @click="addNum(9)" class="numbers bg-light text-dark fs-1 m-2">9</b-button>
              </div>
              <div class=" w-100 d-flex">
                <b-button @click="addNum(4)" class="numbers bg-light text-dark fs-1 m-2">4</b-button>
                <b-button @click="addNum(5)" class="numbers bg-light text-dark fs-1 m-2">5</b-button>
                <b-button @click="addNum(6)" class="numbers bg-light text-dark fs-1 m-2">6</b-button>
              </div>
              <div class=" w-100 d-flex">
                <b-button @click="addNum(1)" class="numbers bg-light text-dark fs-1 m-2">1</b-button>
                <b-button @click="addNum(2)" class="numbers bg-light text-dark fs-1 m-2">2</b-button>
                <b-button @click="addNum(3)" class="numbers bg-light text-dark fs-1 m-2">3</b-button>
              </div>
              <div class=" w-100 d-flex">
                <b-button @click="clear()" class="numbers bg-light text-dark fs-1 m-2">C</b-button>
                <b-button @click="addNum(0)" class="numbers bg-light text-dark fs-1 m-2">0</b-button>
                <b-button @click="del()" class="numbers bg-light text-dark fs-1 m-2">⟵</b-button>
              </div>
            </b-container>
          </div>
          <div class="col h-100 p-3 shadow d-flex flex-column justify-content-between">
            <header class="text-end">
              <font-awesome-icon @click="hideModal" class="times" icon="times"/>
            </header>
            <div>
              <div class="d-flex justify-content-between">
                <div><h3>К оплате</h3></div>
                <div><h3>{{ getTotal | decimal }}</h3></div>
              </div>
              <div class="d-flex mt-4 justify-content-between h-50">
                <div><h4>Сдача</h4></div>
                <div><h4>{{ totalPayBack | decimal }}</h4></div>
              </div>
            </div>
            <div>
              <div class="d-flex justify-content-between align-items-center flex-wrap">
                <div><h5>Наличными</h5></div>
                <b-input-group class="paymentInput w-50">
                  <b-form-input
                      placeholder="0"
                      v-model="ClientPayment.PaymentCashCurrencyValue.Value"
                      @focus="onFocusToField('PaymentCashCurrencyValue')"
                      @blur="onBlurFromField('PaymentCashCurrencyValue')"
                      type="number">
                  </b-form-input>
                  <b-input-group-append>
                    <b-button @click="wholeAmount('PaymentCashCurrencyValue')" class="pay border rounded-0 rounded-end">
                      Вся сумма
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="d-flex justify-content-between align-items-center mt-4 flex-wrap">
                <div><h5>Карточкой</h5></div>
                <b-input-group class="paymentInput w-50">
                  <b-form-input
                      placeholder="0"
                      v-model="ClientPayment.PaymentCardCurrencyValue.Value"
                      @focus="onFocusToField('PaymentCardCurrencyValue')"
                      @blur="onBlurFromField('PaymentCardCurrencyValue')"
                      type="number">
                  </b-form-input>
                  <b-input-group-append>
                    <b-button @click="wholeAmount('PaymentCardCurrencyValue')" class="pay border rounded-0 rounded-end">
                      Вся сумма
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <h5>Распечатать чек</h5>
              <switches v-model="isPrint" theme="bulma" type-bold="true" color="blue"></switches>
            </div>
            <div class="d-flex justify-content-between">
              <b-button class="bton cancel" @click="hideModal">Отменить</b-button>
              <b-button v-b-modal.success-modal class="bton pay" @click="clientPayment()">Оплатить</b-button>
            </div>
          </div>

          <b-modal id="success-modal" no-close-on-esc no-close-on-backdrop ref="success-modal" hide-header hide-footer title="Modal with Popover" ok-only>
            <div>
              <b-iconstack font-scale="6" class=" w-100 text-success">
                <b-icon stacked icon="circle"></b-icon>
                <b-icon stacked icon="check"></b-icon>
              </b-iconstack>
              <div class="text-center text-success fs-5 mt-3">
                <p class=" text-center">Чек закрыт</p>
              </div>
              <div class="text-center fs-5 mt-4">
                <p class=" text-center">Оплаченная сумма {{ successPayment.total | decimal }}</p>
              </div>
              <div class="text-center fs-5 mt-4">
                <p class=" text-center">Сдача {{ successPayment.payBack | decimal }}</p>
              </div>
              <div class=" text-center p-2">
                <b-button class=" rounded mt-5" @click="finish" variant="success">OK, новый заказ</b-button>
              </div>
            </div>
          </b-modal>
        </b-container>
      </b-modal>
    </b-overlay>
  </b-col>
</template>

<script>
import Switches from 'vue-switches';
import math from '/src/plugins/math';
import decimal from '/src/filters/index'
import SaleService from '/src/services/item/sale/sale.service'
import SaleRegisterService from '/src/services/item/sale/sale.register.service'
import SaleItemService from '/src/services/item/sale/sale.item.service'
import ClientPaymentService from '/src/services/client/client.payment.service'
import Downloader from '/src/downloader'
import {ApiHost} from "@/constants/settings";
import {mapMutations} from "vuex";
import _ from "lodash"
import CompleteSaleService from '@/services/item/sale/complete.sale.service'

export default {
  data() {
    return {
      ApiHost,
      ClientPayment: null,
      isPrint: false,
      focusInField: null,
      blurFromField: null,
      loadingOrders: false,
      windowWidth: window.innerWidth,
      sales: [],
      Items: [],
      successPayment: {
        total: null,
        payBack: null
      }
    }
  },
  components: {
    Switches,
  },
  created() {
    this.resetClientPayment()
  },
  computed: {
    getNewItems() {
      return this.$store.state.Sale.Items;
    },
    selectedClient() {
      return this.$store.state.selectedClient
    },
    getTotal() {
      return this.Items.reduce(function (sum, item) {
        return sum + (item.WarehouseItem.Item.PriceCurrencyValue.Value * item.Quantity)
      }, 0)
    },
    getService() {
      let servicePay = (this.getTotal / 100) * 20;
      return servicePay
    },
    getOrders() {
      return this.$store.getters.getOrders
    },
    totalPaid() {
      let paymentCashAmount = this.ClientPayment.PaymentCashCurrencyValue.CurrencyId ? this.mathHelper(+this.ClientPayment.PaymentCashCurrencyValue.Value, +this.ClientPayment.PaymentCashCurrencyValue.Rate)
          : +this.ClientPayment.PaymentCashCurrencyValue.Value;

      let paymentCardAmount = this.ClientPayment.PaymentCardCurrencyValue.CurrencyId ? this.mathHelper(+this.ClientPayment.PaymentCardCurrencyValue.Value, +this.ClientPayment.PaymentCardCurrencyValue.Rate)
          : +this.ClientPayment.PaymentCardCurrencyValue.Value;

      let total_paid = paymentCashAmount + paymentCardAmount

      if (total_paid < 0)
        total_paid = 0;

      return total_paid;

    },
    totalPayBack() {
      let totalPayBack = (+this.ClientPayment.PaymentCashCurrencyValue.Value + +this.ClientPayment.PaymentCardCurrencyValue.Value) - this.getTotal;

      if (totalPayBack < 0)
        totalPayBack = 0;

      return totalPayBack;
    },
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    };
  },
  methods: {
    ...mapMutations(['clearItems', 'resetSelectedClientSale', 'removeAllItems']),
    resetClientPayment() {
      this.ClientPayment = {
        PaymentCashCurrencyValue: {
          Currency: null,
          CurrencyId: null,
          CurrencyValueId: null,
          Rate: null,
          Value: 0
        },
        PaymentCardCurrencyValue: {
          Currency: null,
          CurrencyId: null,
          CurrencyValueId: null,
          Rate: null,
          Value: 0
        },
        PaymentBankTransferCurrencyValue: {
          Currency: null,
          CurrencyId: null,
          CurrencyValueId: null,
          Rate: null,
          Value: 0
        },
        HumoTransferCurrencyValue: {
          Currency: null,
          CurrencyId: null,
          CurrencyValueId: null,
          Rate: null,
          Value: 0
        },
        OtherTransferCurrencyValue: {
          Currency: null,
          CurrencyId: null,
          CurrencyValueId: null,
          Rate: null,
          Value: 0
        },
        Description: '',
        Client: null,
        CreatedAt: new Date(),
        ClientPaymentId: null,
        SystemTotalValue: null,
        Username: null,
      };
    },
    loadNotApprovedItems() {
      this.loadingOrders = true;
      let selectedWarehouse = JSON.parse(localStorage.getItem('selectedWarehouse'));
      SaleService.getAll({
        query: null,
        desc: true,
        sort: null,
        skip: 0,
        limit: 1,
        client: this.$store.state.selectedClient.ClientId,
        startDate: null,
        endDate: null,
        approved: false,
        debt: null,
        warehouse: selectedWarehouse.WarehouseId
      }).then(sales => {
        this.loadingOrders = false;
        this.sales = sales;
        this.loadSaleRegister()
        _.map(sales, (sale) => {
          this.$store.commit('selectClientSale', {sale: sale});
        })
      });
    },
    loadSaleRegister(id) {
      let vm = this;
      vm.loadingOrders = true;
      let SaleID = this.sales.find(s => s.SaleId)
      if (SaleID) {
        SaleRegisterService.get(SaleID.SaleId).then(({sale, saleItems}) => {
          vm.loadingOrders = false;
          vm.Sale = sale;
          vm.$set(vm.Sale, 'ClientPaymentCurrencyValue', {
            Currency: null,
            CurrencyId: null,
            CurrencyValueId: null,
            Rate: null,
            Value: null
          });
          if (sale.ClientPayment) {
            vm.Sale.ClientPaymentCurrencyValue = sale.ClientPayment.CurrencyValue;
            vm.$delete(vm.Sale, 'ClientPayment');
          } else {
            vm.$delete(vm.Sale, 'ClientPayment');
          }
          vm.$store.commit('resetNotApprovedItems'),
              _.each(saleItems, saleItem => {
                this.$store.commit('getNotApprovedItems', {notApprovedItems: saleItem});
              });
          vm.Items = saleItems;
        });
      } else {
        vm.loadingOrders = false;
        console.log("Не найдено неподвержденных продаж")
        vm.Items = [];
      }
    },
    plus(item) {
      let vm = this;
      SaleItemService.edit(item.SaleItemId, {
        sale: this.Sale, saleItem: {
          SaleId: this.Sale.SaleId,
          ItemId: item.WarehouseItem.Item.ItemId,
          Quantity: ++item.Quantity,
          CurrencyValue: {
            Value: item.PriceCurrencyValue.Value,
            Rate: item.PriceCurrencyValue.Rate,
            CurrencyId: item.PriceCurrencyValue.CurrencyId
          },
        }
      })
          .then((response) => {
            vm.editingItemOldValue = null;
            vm.loadSaleRegister();
            // vm.loadNotApprovedItems()
          }, function (error) {
            vm.editingItemOldValue = null;
            this.$toast.open({
              message: "Возникла ошибка",
              type: "error",
              duration: 4000,
              position: 'top-right',
              dismissible: true
            })
          });
    },
    min(item) {
      let vm = this;
      SaleItemService.edit(item.SaleItemId, {
        sale: this.Sale, saleItem: {
          SaleId: this.Sale.SaleId,
          ItemId: item.WarehouseItem.Item.ItemId,
          Quantity: --item.Quantity,
          CurrencyValue: {
            Value: item.PriceCurrencyValue.Value,
            Rate: item.PriceCurrencyValue.Rate,
            CurrencyId: item.PriceCurrencyValue.CurrencyId
          },
        }
      })
          .then((response) => {
            // vm.editingItemOldValue = null;
            vm.loadSaleRegister(vm.Sale.SaleId);
            // vm.loadNotApprovedItems()
            if (item.Quantity <= 0) {
              SaleItemService.delete(item.SaleItemId).then(() => {
                vm.loadSaleRegister();
                // vm.loadNotApprovedItems()
                this.$toast.open({
                  message: "Удалено: " + item.Name,
                  type: "warning",
                  duration: 1000,
                  position: 'top-right',
                  dismissible: true
                })
              });
            }
            // vm.loadSaleRegister(vm.Sale.SaleId);
          }, function (error) {
            vm.editingItemOldValue = null;
            this.$toast.open({
              message: "Возникла ошибка",
              type: "error",
              duration: 4000,
              position: 'top-right',
              dismissible: true
            })
          });
    },
    onFocusToField(field) {
      let vm = this;
      vm.focusInField = field;
      if (vm.focusInField === 'PaymentCardCurrencyValue') {
        if (vm.ClientPayment.PaymentCardCurrencyValue.Value === 0) {
          vm.ClientPayment.PaymentCardCurrencyValue.Value = ''
        }
      } else {
        if (vm.ClientPayment.PaymentCashCurrencyValue.Value === 0) {
          vm.ClientPayment.PaymentCashCurrencyValue.Value = ''
        }
      }
    },
    onBlurFromField(field) {
      let vm = this;
      vm.blurFromField = field;
      if (vm.blurFromField === 'PaymentCardCurrencyValue') {
        if (vm.ClientPayment.PaymentCardCurrencyValue.Value === '') {
          vm.ClientPayment.PaymentCardCurrencyValue.Value = 0
        }
      } else {
        if (vm.ClientPayment.PaymentCashCurrencyValue.Value === '') {
          vm.ClientPayment.PaymentCashCurrencyValue.Value = 0
        }
      }
    },
    removeProduct(item) {
      let vm = this;
      SaleItemService.delete(item.SaleItemId).then(() => {
        vm.loadSaleRegister(vm.Sale.SaleId);
        this.$toast.open({
          message: "Удалено: " + item.WarehouseItem.Item.Name,
          type: "warning",
          duration: 1000,
          position: 'top-right',
          dismissible: true
        })
      });
    },
    clientPayment() {
      let vm = this;
      vm.successPayment.total = vm.totalPaid;
      vm.successPayment.payBack = vm.totalPayBack;

      if (vm.totalPayBack > 0 && vm.ClientPayment.PaymentCashCurrencyValue.Value > 0) {
        vm.ClientPayment.PaymentCashCurrencyValue.Value = vm.ClientPayment.PaymentCashCurrencyValue.Value - vm.totalPayBack;
      }

      let data = {
        Description: '',
        ClientId: this.Sale.Client.ClientId,
        CreatedAt: new Date(this.Sale.CreatedAt),
        PaymentCashCurrencyValue: this.ClientPayment.PaymentCashCurrencyValue,
        PaymentCardCurrencyValue: this.ClientPayment.PaymentCardCurrencyValue,
        PaymentBankTransferCurrencyValue: this.ClientPayment.PaymentBankTransferCurrencyValue,
        HumoTransferCurrencyValue: this.ClientPayment.HumoTransferCurrencyValue,
        OtherTransferCurrencyValue: this.ClientPayment.OtherTransferCurrencyValue,
      }
      ClientPaymentService.create(data).then(response => {
        CompleteSaleService.edit(vm.Sale.SaleId, {
          EmployeeId: null,
          Description: null,
          SaleNumber: null,
          ClientPaymentId: response.ClientPaymentId,
          DiscountCurrencyValue: {
            Value: 0,
            Rate: null,
            CurrencyId: null
          },
          ClientSaleBonusUseCurrencyValue: {
            Value: 0,
            Rate: null,
            CurrencyId: null,
          },
          ClientSaleBonusCurrencyValue: {
            Value: 0,
            Rate: null,
            CurrencyId: null,
          },
          RegistrationType: null
        }).then(response => {
          if (vm.isPrint) {
            Downloader.downloadSalePdf(vm.Sale, true)
          }
        })
      })
    },
    addNum(key) {
      if (this.focusInField && this.focusInField === 'PaymentCardCurrencyValue') {
        this.ClientPayment.PaymentCardCurrencyValue.Value = String(this.ClientPayment.PaymentCardCurrencyValue.Value + key).slice(0);
      } else {
        this.ClientPayment.PaymentCashCurrencyValue.Value = String(this.ClientPayment.PaymentCashCurrencyValue.Value + key).slice(0);
      }
    },
    clear() {
      this.ClientPayment.PaymentCardCurrencyValue.Value = 0;
      this.ClientPayment.PaymentCashCurrencyValue.Value = 0;
      this.focusInField = '';
    },
    del() {
      if (this.focusInField && this.focusInField === 'PaymentCardCurrencyValue') {
        this.ClientPayment.PaymentCardCurrencyValue.Value = this.ClientPayment.PaymentCardCurrencyValue.Value.slice(0, this.ClientPayment.PaymentCardCurrencyValue.Value.length - 1);
      } else {
        this.ClientPayment.PaymentCashCurrencyValue.Value = this.ClientPayment.PaymentCashCurrencyValue.Value.slice(0, this.ClientPayment.PaymentCashCurrencyValue.Value.length - 1);
      }
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    openModal() {
      this.$refs['my-modal'].show()
    },
    mathHelper(item1, item2) {
      return math.multiply(item1, item2);
    },
    wholeAmount(key) {
      let totalPay = this.getTotal
      this.ClientPayment[key].Value = totalPay
    },

    finish() {
      let vm = this;
      vm.loadNotApprovedItems()
      vm.clear()
      vm.$refs['success-modal'].hide()
      vm.hideModal();
      this.resetSelectedClientSale()
      this.removeAllItems()
    }
  },
  watch: {
    selectedClient(newClient) {
      if (newClient) {
        this.loadNotApprovedItems();
      }
    },
    getNewItems(newItem) {
      if (newItem) {
        if (this.$store.state.selectedClientSale) {
          this.loadSaleRegister()
        } else {
          this.loadNotApprovedItems()
          this.loadSaleRegister()
        }
      }
    }
  }
}
</script>

<style scoped>
.orders-header {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orders-footer {
  height: 180px;
}

.counter {
  display: flex;
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  background: rgb(239, 239, 239);
  border-radius: 25px;
}

.plus {
  border-radius: 0px 25px 25px 0px;
  font-size: 1.2rem;
  border: none;
}

.min {
  border-radius: 25px 0px 0px 25px;
  font-size: 1.2rem;
  border: none;
}

.jcsb {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.scrolling {
  min-width: 100%;
  height: calc(100vh - 260px);
  overflow-y: auto;
}

.scrolling::-webkit-scrollbar {
  background-color: #fff;
  border-radius: 9px;
  width: 5px;
  height: 8px;
}

.scrolling::-webkit-scrollbar-thumb {
  background: rgb(193, 193, 193);
  border-radius: 9px;
  background-clip: content-box;
}

.t-icon {
  margin: 0 5px;
  cursor: pointer;
}

.t-icon:hover {
  color: red;
  animation: trash 0.2s 2 alternate;
  cursor: pointer;
}

@keyframes trash {
  25% {
    transform: rotate(20deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-20deg);
  }
}

.numbers {
  width: 30%;
  height: 22vh;
  display: flex;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.times {
  cursor: pointer;
  font-size: 2rem;
}

.bton {
  padding: 16px 48px;
  font-size: 1.5rem;
  border-radius: 50px;
}

.pay {
  background: rgb(20, 83, 136);
  border: none;
}

.cancel {
  background: rgb(236, 236, 236);
  border: none;
  color: #000;
}

.pay:hover {
  background: rgb(14, 63, 100);
}

.cancel:hover {
  background: rgb(220, 220, 220);
  color: #000;
}

.main {
  display: flex;
}

.myPrinter {
  display: none;
}

@media print {
  .myPrinter {
    display: block;
  }

  .main {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) and (min-width: 200px) {
  .scrolling {
    height: calc(100vh - 270px);
  }
}

@media only screen and (max-width: 600px) {
  .bton {
    padding: 10px 28px;
    font-size: 1.3rem;
    border-radius: 50px;
  }
}

@media only screen and (max-width: 450px) {
  .bton {
    padding: 10px 25px;
    font-size: 1.1rem;
    border-radius: 50px;
  }

  .paymentInput {
    width: 100% !important;
  }
}
</style>