import {ApiHost} from "../constants/settings";
import axios from "axios";
import printJS from "print-js";

class Downloader {
    downloadSalePdf(Sale, isPrint) {
        const url = `${ApiHost}/api/SalePdf/${Sale.SaleId}`;
        this._downloadUrl(url, isPrint);
    }

    _downloadUrl(url, isPrint) {
        let token = JSON.parse(localStorage.auth);
        let config = {
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${token.access_token}`
            }
        }

        let promise = axios.get(url, config);

        promise.then(response => {
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            if (isPrint) {
                printJS(url);
            } else {
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute('download', response.headers['x-file-name']);

                document.body.appendChild(link);

                link.click();
                link.remove();
                window.URL.revokeObjectURL(url)
            }
        }).catch(error => {
            console.log(error)
        });

        return promise;
    }
}

export default new Downloader()