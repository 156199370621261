<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12" class="p-3 bg-success text-light d-flex align-items-center">
        <b-icon-chevron-left
            font-scale="1.5"
            class="me-3 mb-1"
            style="cursor: pointer"
            @click="$router.go(-1)"
        />
        <h3>Заказы на сегодня</h3>
      </b-col>
    </b-row>

    <b-overlay :show="loading">
      <b-row v-if="orders.length >= 0 && !loading">
        <b-col cols="12" lg="6" v-for="order in orders" :key="order.SaleItemId">
          <b-card class="my-3 shadow" no-body>
            <b-card-header header-class="bg-light d-flex justify-content-between">
              <span>Зазаз: <span class="text-primary">№{{ order.saleNumber }}</span></span>
              <span>Клиент: {{ order.client }}</span>
              <span>Дата: {{ moment(order.created_at).lang('ru').fromNow() }}</span>
            </b-card-header>
            <b-card-body class="p-0">
              <b-table-simple table-class="m-0">
                <thead class="fw-bold">
                <tr>
                  <td>Название</td>
                  <td>Количество</td>
                </tr>
                </thead>
                <tbody v-for="item in order.order_items" :key="item.SaleItemId">
                <tr>
                  <th class="fw-normal">{{ item.WarehouseItem.Item.Name }}</th>
                  <th class=" fw-normal">
                    <span>{{ item.Quantity }}</span>
                    <span>{{ item.WarehouseItem.Item.Measurement }}</span>
                  </th>
                </tr>
                </tbody>
              </b-table-simple>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>

<script>
import moment from 'moment'
import {ApiHost} from "@/constants/settings";
import SaleService from '/src/services/item/sale/sale.service';
import SaleRegisterService from "@/services/item/sale/sale.register.service";

export default {
  name: "chef-interface",
  data() {
    return {
      moment,
      ApiHost,
      orders: [],
      loading: false,
    }
  },

  mounted() {
    this.loadSales();
  },

  methods: {
    reloadOrdersList() {
      setTimeout(() => {
        this.loadSales();
      }, 10000)
    },

    loadSales() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        this.orders = [];
        const today = new Date();
        const selectedWarehouse = JSON.parse(localStorage.getItem('selectedWarehouse'));

        SaleService.getAll({
          query: null,
          desc: true,
          sort: null,
          skip: 0,
          limit: null,
          client: null,
          startDate: today,
          endDate: null,
          approved: false,
          debt: null,
          warehouse: selectedWarehouse.WarehouseId
        }).then(loadedSales => {
          this.loadSaleItems(loadedSales, 0);
          resolve();
        }).catch(error => reject(error))
      })
    },

    loadSaleItems(sales, currentIndex) {
      return new Promise((resolve, reject) => {
        if (currentIndex < sales.length) {
          const sale = sales[currentIndex];
          SaleRegisterService.get(sale.SaleId).then(({sale, saleItems}) => {
            const order = {
              saleNumber: sale.SaleId,
              client: sale.Client.Name,
              created_at: sale.CreatedAt,
              order_items: saleItems,
            }
            this.orders.push(order);
            this.loadSaleItems(sales, currentIndex + 1).then(resolve, reject);
          }).catch(error => console.log(error))
        } else {
          this.loading = false;
          this.reloadOrdersList();
          resolve();
        }
      })
    }
  }
}
</script>

<style scoped>

</style>