import axios from "axios";
import BaseService from "../../base/base.service";
import $bus from "/src/plugins/global.events.bus"
import * as saleEvents from '/src/events/item'

class SaleService extends BaseService {
    get modelName() {
        return "Sale"
    }

    getAll({skip, limit, query, sort, desc, client, startDate, endDate, approved, debt, warehouse}) {
        let params = {
            q: query,
            sort: sort,
            desc: desc,
            skip: skip,
            limit: limit,
            client: client,
            start: this._getDateDDMMYYYFormat(startDate),
            end: this._getDateDDMMYYYFormat(endDate),


            approved: approved,
            debt: debt,
            warehouse: warehouse
        };
        return axios.get(this.getAllUrl(), {params: params}).then(response => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    delete(id) {
        return axios.delete(this.getDeleteUrl(id)).then(() => {
            $bus.$emit(saleEvents.ITEM_SALE_DELETED);
        }).catch(() => {
        })
    }

    edit(Sale) {
        return axios.put(this.getEditUrl(Sale.SaleId), {
            CreatedAt: Sale.CreatedAt ? this._getDateDDMMYYYFormat(new Date(Sale.CreatedAt)) : null,
            ClientId: Sale.Client.ClientId,
            IsApproved: Sale.IsApproved,
            Description: Sale.Description,
            SaleNumber: Sale.SaleNumber ? Sale.SaleNumber : null,
            WarehouseId: Sale.Warehouse.WarehouseId,
            ClientPaymentId: Sale.ClientPaymentId ? Sale.ClientPaymentId : null,
            DiscountCurrencyValue: Sale.DiscountCurrencyValue,
            ClientSaleBonusUseCurrencyValue: Sale.ClientSaleBonusUseCurrencyValue,
            ClientSaleBonusCurrencyValue: Sale.ClientSaleBonusCurrencyValue,
        }).then((response) => {
            $bus.$emit(saleEvents.ITEM_SALE_EDITED);
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }
}

export default new SaleService()