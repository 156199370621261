export const ITEM_ADDED  = "ITEM_ADDED";
export const ITEM_EDITED = "ITEM_EDITED";
export const ITEM_DELETED = 'ITEM_DELETED';
export const ITEM_REVISION_ADDED = "ITEM_REVISION_ADDED";


export const ITEM_PURCHASE_REGISTERED = "ITEM_PURCHASE_REGISTERED";
export const ITEM_PURCHASE_EDITED = "ITEM_PURCHASE_EDITED";
export const ITEM_PURCHASE_DELETED = "ITEM_PURCHASE_DELETED";

export const ITEM_PURCHASE_POSITION_CREATED = "ITEM_PURCHASE_POSITION_CREATED";
export const ITEM_PURCHASE_POSITION_EDITED = "ITEM_PURCHASE_POSITION_EDITED";
export const ITEM_PURCHASE_POSITION_DELETED = "ITEM_PURCHASE_POSITION_DELETED";


export const ITEM_CATEGORY_ADDED = 'ITEM_CATEGORY_ADDED';
export const ITEM_CATEGORY_EDITED = 'ITEM_CATEGORY_EDITED';

export const ITEM_SALE_EDITED = 'ITEM_SALE_EDITED';
export const ITEM_SALE_DELETED = 'ITEM_SALE_DELETED';


export const ITEM_REFUND_REGISTERED = "ITEM_REFUND_REGISTERED";
export const ITEM_REFUND_DELETED = "ITEM_REFUND_DELETED";
export const ITEM_REFUND_EDITED = "ITEM_REFUND_EDITED";
export const ITEM_REFUND_POSITION_EDITED = "ITEM_REFUND_POSITION_EDITED";
export const ITEM_REFUND_POSITION_DELETED = "ITEM_REFUND_POSITION_DELETED";
export const ITEM_REFUND_POSITION_CREATED = "ITEM_REFUND_POSITION_CREATED";