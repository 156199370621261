import {ApiHost} from '../../constants/settings'

export default class BaseService {
    constructor() {
        if (!this.modelName) {
            throw new Error('Child service class not provide modelName')
        }
    }

    getSingleUrl(id) {
        return `${ApiHost}/api/${this.modelName}/${id}`
    }

    getAllUrl() {
        return `${ApiHost}/api/${this.modelName}`
    }

    getCreateUrl() {
        return `${ApiHost}/api/${this.modelName}`
    }

    getEditUrl(id) {
        return `${ApiHost}/api/${this.modelName}/${id}`
    }

    getDeleteUrl(id) {
        return `${ApiHost}/api/${this.modelName}/${id}`
    }

    _getDateDDMMYYYFormat(date) {
        if (date) {
            let day = date.getDate().toString();
            day = day.length === 1 ? `0${day}` : day;

            let month = (date.getMonth() + 1).toString();
            month = month.length === 1 ? `0${month}` : month;

            let year = date.getFullYear().toString();

            return `${day}.${month}.${year}`;
        }
        return "";
    }
}