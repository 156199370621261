<template>
  <b-col v-if="getBar" lg="1" md="1" sm="12" cols="12" class="d-xl-flex d-lg-flex d-md-flex" style="font-size: 90% !important;">
    <div class="bar bg-dark">
      <div class="logo text-light">
        <h3>Столы</h3>
      </div>
      <div class="tables">
        <div v-for="(client, index) in sortedArray" class="col-md-12 col-4" :key="index">
          <div :class="{isClicked : activeClient === index}">
            <div
                @click="selectClient(client, index)"
                class="table-btn flex-wrap bg-transparent text-light border border-light rounded b-avatar">
              {{ client.Name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-col>
</template>

<script>
import clientService from '../services/client/client.service'
import {mapMutations} from 'vuex';
import _ from "lodash"

export default {
  data() {
    return {
      clients: [],
      windowWidth: window.innerWidth,
      isClicked: false,
      activeClient: null,
    }
  },
  computed: {
    getBar() {
      return this.$store.getters.getBar
    },
    sortedArray() {
      let sortedClients = _.sortBy(this.clients, ['ClientId']);
      return sortedClients
    }
  },
  mounted() {
    this.loadClients();
  },
  methods: {
    ...mapMutations(['removeAllItems', 'resetSelectedClientSale']),
    selectClient(client, index) {
      let vm = this;
      this.activeClient = index;
      vm.$store.commit('selectClient', {client: client});
      this.removeAllItems();
      this.resetSelectedClientSale()
    },
    loadClients() {
      clientService.getAll({
        query: null,
        desc: true,
        sort: null,
        skip: null,
        limit: null,
        debt: null
      }).then(clients => {
        this.clients = clients;

        setTimeout(() => {
          let defaultClient = this.sortedArray[0]
          this.selectClient(defaultClient, 0)
        }, 100)
      })
    }
  }
}
</script>

<style scoped>
.bar {
  width: 100%;
  height: 100vh;
  border-right: 1px solid grey;
}

.logo {
  width: 100%;
  height: 80px;
  border-bottom: 1px solid grey;
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-btn {
  cursor: pointer;
  width: 80%;
  height: 50px;
  margin: 5px 0;
  font-size: 1rem;
  border: 1px solid #fff !important;
  background: transparent;
}

.tables {
  width: 100%;
  height: calc(100vh - 80px);
  text-align: center;
  padding: 20px 0;
  overflow-y: scroll;
}

.tables::-webkit-scrollbar {
  background-color: #000;
  border-radius: 9px;
  width: 4px;
  height: 5px;
}

.tables::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 9px;
  background-clip: content-box;
}

.isClicked {
  border-left: 4px solid white !important;
}

.isClicked > .table-btn {
  background: #fff !important;
  color: black !important;
}

@media only screen and (max-width: 1080px) and (min-width: 768px) {
  .table-btn {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 767px) and (min-width: 714px) {
  .tables {
    display: flex;
    flex-wrap: wrap;
    justify-content: unset !important;
  }
}

@media only screen and (max-width: 767px) {
  .table-btn {
    width: 80px;
    height: 80px;
    border-radius: 25px;
    margin: 10px;
    background: #fff;
    color: #00022e !important;
    font-size: 1.3rem;
    border: 2px solid #00022e !important;
  }

  .isClicked > .table-btn {
    background: #00022e !important;
    color: #fff !important;
  }

  .tables {
    display: flex;
    flex-wrap: wrap;
    /*justify-content: unset;*/
    height: calc(100vh - 130px);
    padding: 5px;
  }

  .bar {
    background: #fff !important;
  }

  .logo h3 {
    color: #000 !important;
    font-size: 2rem !important;
  }
}
</style>