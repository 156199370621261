<template>
  <div id="app">
      <router-view></router-view>
  </div>
</template>

<script>
import AuthService from '/src/services/account/auth.service';
export default {
  name: 'App',
  created() {
    let vm = this;
    if (localStorage.username && localStorage.username.length === 9) {
      vm.signOut().then(() => {
        vm.$router.push({name: 'login'});
      });
    }
    if (AuthService.fetchAuth()) {
      vm.$store.dispatch("getPermissions").then(res => {
      }, error => {
        this.$toast.open({
          message: "Возникла ошибка при получении разрешение",
          type: "error",
          duration: 3000,
          position: 'top-right',
          dismissible: true
        })
      });
      if (vm.$store.getters.isTokenSoonExpires) {
        vm.$router.push({name: "login"});
      }
    } else {
      vm.$router.push({name: "login"});
    }
  },
}
</script>
