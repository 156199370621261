import SaleService from '/src/services/item/sale/sale.service'

export default {
    state: {
        Items: [],
        Sales: null,
        notApprovedItems: [],
    },
    getters: {
        getNotApprovedItems(state) {
            return state.notApprovedItems
        }
    },
    actions: {},
    mutations: {
        getNotApprovedItems(state, {notApprovedItems}) {
            state.notApprovedItems.push(notApprovedItems)
        },
        getNewItems(state, {newItems}) {
            state.Items.push(newItems)
        },
        resetNotApprovedItems(state) {
          state.notApprovedItems = []
        },
        removeAllItems(state) {
            state.notApprovedItems = []
        },
        clearItems(state) {
            state.Items = []
        }
    }
}