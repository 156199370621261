import BaseService from "../../base/base.service";
import axios from "axios";

class ItemCategoryService extends BaseService {
    get modelName() {
        return "ItemCategory";
    }

    getAll({query, sort, desc, skip, limit}) {
        let params = {
            q: (typeof (query) === "undefined" || query === null) ? "" : query,
            sort: sort,
            desc: desc,
            skip: skip,
            limit: limit
        };
        return axios.get(this.getAllUrl(), {params: params}).then(response => {
            return response.data
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    get(id) {
        return axios.get(this.getSingleUrl(id)).then((response) => {
            return response.data;
        }).catch(() => {
        });
    }
}

export default new ItemCategoryService();