import BaseService from "../base/base.service";
import axios from "axios";
import $bus from "/src/plugins/global.events.bus"
import * as clientPaymentEvents from "/src/events/client.payment"

class ClientPaymentService extends BaseService {
    get modelName() {
        return "ClientPayment";
    }

    create({
               Description,
               ClientId,
               CreatedAt,
               PaymentCashCurrencyValue,
               PaymentCardCurrencyValue,
               PaymentBankTransferCurrencyValue,
               HumoTransferCurrencyValue,
               OtherTransferCurrencyValue
           }) {
        let paymentCashCurrencyValue = PaymentCashCurrencyValue.Value === 0 ? null : {
            Value: PaymentCashCurrencyValue.Value,
            Rate: PaymentCashCurrencyValue.Rate,
            CurrencyId: PaymentCashCurrencyValue.CurrencyId
        }
        let paymentCardCurrencyValue = PaymentCardCurrencyValue.Value === 0 ? null : {
            Value: PaymentCardCurrencyValue.Value,
            Rate: PaymentCardCurrencyValue.Rate,
            CurrencyId: PaymentCardCurrencyValue.CurrencyId
        }
        let paymentBankTransferCurrencyValue = PaymentBankTransferCurrencyValue.Value === 0 ? null : {
            Value: PaymentBankTransferCurrencyValue.Value,
            Rate: PaymentBankTransferCurrencyValue.Rate,
            CurrencyId: PaymentBankTransferCurrencyValue.CurrencyId
        }
        let humoTransferCurrencyValue = HumoTransferCurrencyValue.Value === 0 ? null : {
            Value: HumoTransferCurrencyValue.Value,
            Rate: HumoTransferCurrencyValue.Rate,
            CurrencyId: HumoTransferCurrencyValue.CurrencyId
        }
        let otherTransferCurrencyValue = OtherTransferCurrencyValue.Value === 0 ? null : {
            Value: OtherTransferCurrencyValue.Value,
            Rate: OtherTransferCurrencyValue.Rate,
            CurrencyId: OtherTransferCurrencyValue.CurrencyId
        }
        return axios.post(this.getCreateUrl(), {
            PaymentCashCurrencyValue: paymentCashCurrencyValue,
            PaymentCardCurrencyValue: paymentCardCurrencyValue,
            PaymentBankTransferCurrencyValue: paymentBankTransferCurrencyValue,
            HumoTransferCurrencyValue: humoTransferCurrencyValue,
            OtherTransferCurrencyValue: otherTransferCurrencyValue,
            Description: Description || null,
            ClientId: ClientId,
            CreatedAt: CreatedAt ? this._getDateDDMMYYYFormat(CreatedAt) : null,
        }).then((response) => {
            $bus.$emit(clientPaymentEvents.ADDED, response.data);
            return response.data;
        }).catch((error) => {
            return Promise.reject(error)
        });
    }
}
export default new ClientPaymentService()