import authService from '../services/account/auth.service';
import store from '/store/index'

export default (to, from, next) => {
    if (to.meta && to.meta.auth) {
        if (!store.getters.isTokenSoonExpires && (store.getters.isAuthorized || authService.fetchAuth())) {
            next();
        } else {
            store.dispatch('signOut').then(() => {
                next({name: 'login'});
            });
        }
    } else {
        if (!store.getters.isAuthorized || !authService.fetchAuth()) {
            next();
        } else {
            next({name: 'dashboard'});
        }
    }
}