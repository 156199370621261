import WarehouseService from '/src/services/product/warehouse.service'

export default {
    state: {
        warehouses: [],
        selectedWarehouse: null,
        processing: false
    },
    getters: {
        selectedWarehouse: state => state.selectedWarehouse,
        isLoadedWarehouses: state => state.processing,
        getAllWarehouses: state => state.warehouses,
    },
    actions: {
        // getAllWarehouses({commit}, params) {
        //     commit('setProcessing', true);
        //     return new Promise((resolve, reject) => {
        //         WarehouseService.getAll(params).then(function (warehouses) {
        //             commit('setWarehouses', {warehouses: warehouses});
        //             commit('setProcessing', false);
        //             resolve(warehouses);
        //         }, function (error) {
        //             reject(error);
        //             commit('setProcessing', false);
        //         });
        //     });
        // }
    },
    mutations: {
        setProcessing(state, payload) {
            state.processing = payload
        },
        setWarehouses(state, {warehouses}) {
            state.warehouses = warehouses;
        },
        selectWarehouse(state, {warehouse}) {
            state.selectedWarehouse = warehouse;
        }
    }
}