<template>
  <b-container fluid>
    <b-row class="bg-light" style="height: 100vh !important;">
      <b-col cols="12" xl="4" lg="5" md="7" sm="8" class="mx-auto my-auto">
        <b-card class="bg-white" body-class="p-0 shadow">
          <b-card-header header-class="border-success p-3 text-center bg-white">
            <b-card-text class="h2 mt-4">
              Вход
            </b-card-text>
          </b-card-header>
          <b-card-body class="p-xl-5 p-lg-5 p-md-4 p-sm-4 p-3 bg-white">
            <b-form @submit.prevent="formSubmit">

              <b-form-group label="Номер телефона">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    +998
                  </b-input-group-prepend>
                  <the-mask
                      v-model="form.username"
                      type="tel"
                      mask="## ### ## ##"
                      class="login-input border border-start-0"
                      placeholder="-- --- -- --"
                  />
                </b-input-group>

                <template v-if="$v.form.username.$error">
                  <small
                      class="text-danger"
                      v-if="!$v.form.username.required"
                  >Это поле обязательно для заполнения
                  </small>
                  <small
                      class="text-danger"
                      v-if="$v.form.username.required && !$v.form.username.phoneWithout998"
                  >Неправильный формат
                  </small>
                </template>
              </b-form-group>


              <b-form-group label="Пароль" class="my-3">
                <b-form-input class="w-100" v-model="form.password" type="password"></b-form-input>
                <template v-if="$v.form.password.$error">
                  <small
                      v-if="!$v.form.password.required"
                      class="text-danger"
                  >Это поле обязательно для заполнения
                  </small>
                </template>
              </b-form-group>

              <b-button
                  type="submit"
                  variant="success"
                  pill
                  :disabled="processing"
                  class="px-xl-5 px-lg-4 px-md-4 px-sm-4 px-4 py-2 float-end my-3"
              >Вход
              </b-button>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapMutations
} from "vuex";

import {validationMixin} from "vuelidate";
import {TheMask} from 'vue-the-mask'
import {phoneWithout998} from '../../plugins/custom-validators';

const {required, maxLength, MinLength} = require("vuelidate/lib/validators")

export default {
  data() {
    return {
      form: {
        username: "",
        password: ""
      },
    }
  },
  validations: {
    form: {
      username: {
        required,
        phoneWithout998
      },
      password: {
        required
      }
    }
  },
  computed: {
    ...mapGetters(["currentUser", "processing", "loginError"])
  },
  components: {
    TheMask
  },
  mixins: [validationMixin],
  methods: {
    ...mapActions(["login",]),
    formSubmit() {
      let vm = this;
      vm.$store.commit('onLoading')
      vm.$v.$touch();
      vm.$v.form.$touch();
      if (!vm.$v.form.$invalid) {
        vm.login({
          username: '998' + vm.form.username,
          password: vm.form.password
        }).then(function () {
          vm.$store.dispatch("getPermissions").then(res => {
          }, error => {
            this.$toast.open({
              message: "Возникла ошибка при получении разрешение",
              type: "error",
              duration: 3000,
              position: 'top-right',
              dismissible: true
            })
          });
            vm.$store.commit('offLoading')
            vm.$router.push({ name: "dashboard" });
        });
      }
    },
  },
  watch: {
    currentUser(val) {
      if (val && val.uid && val.uid.length > 0) {
        setTimeout(() => {
          this.$router.push("/");
        }, 200);
      }
    },
    loginError(val) {
      if (val != null) {
        this.$toast.open({
          message: "Ошибка входа неверное <br/> имя пользователя или пароль, ",
          type: "error",
          duration: 3000,
          position: 'top-right',
          dismissible: true
        })
      }
    }
  }
}
</script>

<style>
.input-group-prepend {
  background: white;
}

.input-group-prepend .input-group-text {
  background-color: transparent;
  border-right: none;
  width: 60px;
  font-size: 18px !important;
}

.login-input {
  width: calc(100% - 60px);
  outline: #00022e;
  font-size: 18px !important;
}
</style>