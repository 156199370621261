import Vue from "vue";
import VueRouter from "vue-router";
import CheckAccessMiddleware from "../src/utils/CheckAccessMiddleware";

import dashboard from '/src/views/app/dashboard'
import Login from "../src/views/user/Login";
import user from "/src/views/user";
import ChefInterface from "@/components/ChefInterface";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "App" */ '/src/views/app'),
        redirect: '/app/dashboard',
        meta: {auth: true},
        children: [
            {
                path: '/app/dashboard',
                name: 'dashboard',
                component: () => import(/* webpackChunkName: "Dashboard" */ '/src/views/app/dashboard'),
                meta: {auth: true},
            },
            {
                path: '/app/chef-interface',
                name: 'chef-interface',
                meta: {auth: true},
                component: () => import(/* webpackChunkName: "Dashboard" */ '/src/components/ChefInterface'),
            }
        ]
    },
    {
        path: '/user',
        redirect: '/user/login',
        meta: {auth: false},
        component: user,
        children: [
            {
                path: 'login',
                name: 'login',
                meta: {auth: false},
                component: Login,
            },
        ]
    },
]

const router = new VueRouter({
    linkActiveClass: "active",
    routes,
    mode: "hash"
});

router.beforeEach(CheckAccessMiddleware);
export default router;

